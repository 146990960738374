:root {
  --primary-color: #2f61f4;
  --primary-color-dark: #1a4683;
  --primary-color-light: #c5e6fd;
  --primary-color-lighter: #eef7ff;
  --secondary-color: #40d0be;
  --secondary-color: #40d0be;
  --secondary-bg-color: #fff;

  --midnight: #111b32;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Ubuntu', 'Open Sans', sans-serif;
  color: var(--midnight);
}
body,
html,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
